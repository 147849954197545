import * as LabelPrimitive from '@radix-ui/react-label';
import { styled } from '../../stitches.config';

const Label = styled(LabelPrimitive.Root, {
  fontSize: '$large',
  fontWeight: '$medium',
  color: '$fgDefault',
  userSelect: 'none',
});

export default Label;
