import { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import createPortalSession from '../api/createPortalSession';

import Logo from '../shared/svgs/Logo';
import { Box, Flex, InlineBox, Spinner } from '../shared/components';
import { useSubScriptionData } from '../hooks/useSubscriptionData';
import Logout from './Logout';
import { Mixpanel } from '../Mixpanel';
import { useAuth } from '../hooks/useAuth';

export default function Sidebar() {
  const { user } = useAuth();
  const [loadingPortal, setLoadingPortal] = useState(false);
  const { subscriptionData } = useSubScriptionData();

  const params = useParams();
  const openCustomerPortal = async () => {
    const token = await user?.getIdToken();

    if (token) {
      setLoadingPortal(true);
      const returnUrl = window.location.href;
      const customerPortalUrl = await createPortalSession(params.customerId ?? '', returnUrl, token);

      if (customerPortalUrl) {
        Mixpanel.track('Edit billing');
        window.location.href = customerPortalUrl;
      }
      setLoadingPortal(false);
    }
  };

  let nextInvoicePrice;
  let nextBillingDate;
  let planName;
  let seats;
  let canceled;
  if (subscriptionData) {
    ({ nextInvoicePrice, nextBillingDate, planName, seats, canceled } = subscriptionData);
  }

  let seatsLabel = '';
  if (seats) {
    seatsLabel = seats === 1 ? '1 seat' : `${seats} seats`;
  } else {
    seatsLabel = '--';
  }

  const ctaText = canceled ? 'Renew subscription' : 'Edit billing';

  return (
    <Flex
      css={{
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '$8',
        background: '$canvasInset',
        borderRight: '1px solid $borderMuted',
        width: '209px',
        flexShrink: 0,
      }}
    >
      <Flex
        css={{
          flexDirection: 'column',
        }}
      >
        <Box
          css={{
            marginBottom: '$7',
            '& svg': {
              color: '$fgDefault',
              fill: '$fgDefault',
              width: '180px',
            },
          }}
        >
          <Link to={`${window.location.origin}`} style={{ color: 'inherit' }}>
            <Logo />
          </Link>
        </Box>
        <Box
          css={{
            maxWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: '$fgDefault',
          }}
          title={user?.email ?? ''}
        >
          {user?.email ?? '--'}
        </Box>
        <InlineBox
          css={{
            fontWeight: '$medium',
            fontSize: '$large',
            lineHeight: '130%',
            marginTop: '$5',
            color: '$fgSubtle',
          }}
        >
          Next billing
        </InlineBox>
        <InlineBox
          css={{
            fontWeight: '$semiBold',
            fontSize: '18px',
            lineHeight: '130%',
            marginTop: '$1',
            color: '$fgDefault',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {nextBillingDate ?? '--'}
        </InlineBox>
        <InlineBox
          css={{
            fontWeight: '$medium',
            fontSize: '14px',
            lineHeight: '130%',
            marginTop: '$4',
            color: '$fgSubtle',
            alignItems: 'flex-end',
          }}
        >
          Next Invoice
        </InlineBox>
        <InlineBox
          css={{
            fontStyle: 'normal',
            fontWeight: '$semiBold',
            fontSize: '18px',
            lineHeight: '130%',
            marginTop: '$1',
            color: '$fgDefault',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {nextInvoicePrice ?? '--'}
        </InlineBox>
        <InlineBox
          css={{
            fontWeight: '$medium',
            fontSize: '14px',
            lineHeight: '130%',
            marginTop: '$5',
            color: '$fgSubtle',
          }}
        >
          Current Plan
        </InlineBox>
        <InlineBox
          css={{
            fontSize: '18px',
            fontWeight: '$semiBold',
            fontStyle: 'normal',
            lineHeight: '23.4px',
            marginTop: '$2',
            color: '$fgDefault',
            textAlign: 'center',
          }}
        >
          {planName}
        </InlineBox>
        <InlineBox
          css={{
            fontSize: '$small',
            fontWeight: '$semiBold',
            fontStyle: 'normal',
            lineHeight: '23.4px',
            marginTop: '$2',
            color: '$danger',
            textAlign: 'center',
          }}
        >
          {canceled ? 'Canceled' : null}
        </InlineBox>
        <InlineBox
          css={{
            width: '80px',
            fontWeight: '$regular',
            fontSize: '14px',
            lineHeight: '130%',
            marginTop: '$2',
            color: '$fgMuted',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {seatsLabel}
        </InlineBox>
        <InlineBox
          css={{
            fontSize: '$large',
            textDecoration: 'underline',
            marginTop: '$5',
            color: '$fgDefault',
            cursor: 'pointer',
          }}
          onClick={openCustomerPortal}
        >
          {loadingPortal ? <Spinner size="1" thickness="1" color="white" /> : ctaText}
        </InlineBox>
      </Flex>
      <Logout />
    </Flex>
  );
}
