import React from 'react';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import { Box } from '../shared/components';
import Scroll from '../shared/components/Scroll';
import { LicenseKey } from '../types';
import LicenseKeyItem from './LicenseKeyItem';

interface LicenseKeyListProps {
  licenseKeys: LicenseKey[] | undefined;
}

export default function LicenseKeyList({ licenseKeys }: LicenseKeyListProps) {
  const [copiedValue, copyToClipboard] = useCopyToClipboard();
  if (licenseKeys) {
    return (
      <>
        <Box
          css={{
            padding: '$4 $6',
            fontWeight: '$bold',
            color: '$fgDefault',
          }}
        >
          {licenseKeys.length > 1 ? 'Team members' : 'Your license key'}
        </Box>
        <Scroll maxWidth="1200px" height="100%">
          <Box
            css={{
              border: '1px solid $borderMuted',
              borderRadius: '$medium',
              fontWeight: '$medium',
              width: '100%',
              maxWidth: '1200px',
            }}
          >
            {licenseKeys.map(
              ({ licenseKey, name, userEmail, status, figmaId, id, expiry, expired, expiring }, index) => (
                <LicenseKeyItem
                  id={id}
                  licenseKey={licenseKey}
                  name={name}
                  userEmail={userEmail}
                  status={status}
                  figmaId={figmaId}
                  index={index}
                  key={id}
                  copiedValue={copiedValue}
                  copyToClipboard={copyToClipboard}
                  expiry={expiry}
                  expired={expired}
                  expiring={expiring}
                />
              ),
            )}
          </Box>
        </Scroll>
      </>
    );
  }

  return <div>There are no license keys connected to your account.</div>;
}
